<!--
 * @Author: wyq
 * @Date: 2021-09-27 09:20:54
 * @LastEditTime: 2022-04-14 15:23:07
 * @LastEditors: yy
 * @Description: 
 * @FilePath: \cat_student\src\views\testingCenter\startCatTesting.vue
-->
<template>
  <el-container class="start-testing round-bg">
    <el-header>
      <div class="testing-header">
        <div class="d-flex">
          <sequence-bar
            class="flex-grow-1"
            inline
            :currentIndex="currentQuestionIndex"
            :value="$store.state.catTesting.questionNum"
          ></sequence-bar>
          <div class="flex-right">
            <v-btn outlined color="error" @click="overTest">结束测试</v-btn>
          </div>
        </div>
        <div class="progress-bar">
          <div class="slider" :style="{width:sliderWidth}"></div>
        </div>
      </div>
    </el-header>
    <el-main>
      <question-container
        ref="questionContent"
        :questionInfo="currentQuestion"
        :loading="loading"
        @submitAnswer="submitCatAnswer"
        @timeOut="catTestTimeout"
      ></question-container>
      <finish-cat-msg-box :visible.sync="finishVisible" @submit="finishTest"></finish-cat-msg-box>
    </el-main>
  </el-container>
</template>
<script>
import sequenceBar from '@/components/testing/sequenceBar.vue'
import questionContainer from '@/components/testing/questionContainer'
import finishCatMsgBox from '@/components/dialogs/finishCatMsgBox'
export default {
  components: {
    sequenceBar,
    questionContainer,
    finishCatMsgBox,
  },
  data() {
    return {
      finishVisible: false,
      questions: [],
      currentQuestionIndex: 0,
      currentQuestion: {},
      loading: false,
    }
  },
  computed: {
    sliderWidth() {
      return (
        parseInt(
          (this.currentQuestionIndex * 100) /
            this.$store.state.catTesting.questionNum
        ) + '%'
      )
    },
    topicId() {
      return Number(this.$route.query.topicId)
    },
  },
  methods: {
    overTest() {
      this.finishVisible = true
    },
    finishTest() {
      this.$router.go(-1)
    },
    init() {
      this.timers = this.$refs.questionContent.getTimer()
      this.startCatTest()
    },
    async startCatTest() {
      let params = {
        topicId: this.topicId,
        subject: this.$store.state.subject,
      }
      const { info: res } = await this.$service.startCatTest(params)
      let currentQuestion = this.$formattedData(res.question)
      this.$set(this, 'currentQuestion', currentQuestion)
      this.$set(this.questions, this.currentQuestionIndex, currentQuestion)
      this.testId = res.testId
      this.timers.currentTimer.start()
      this.timers.totalTimer.start()
    },
    async submitCatAnswer(answer) {
      // this.timers.currentTimer.stop()
      // this.timers.totalTimer.stop()
      this.loading = true

      let params = {
        testId: this.testId,
        questionId: this.currentQuestion.questionId,
        answer: answer,
      }
      try {
        const { info: res } = await this.$service.catPush(params)
        this.loading = false
        if (res.question.questionId) {
          // 考试超时
          if (res.question.questionId == -1) {
            this.$message.info({
              message: '测试时间到,已为您自动提交试题答案',
            })
            this.$router.push({
              name: 'CatReport',
              query: {
                id: this.testId,
              },
            })
            return
          }
          let currentQuestion = this.$formattedData(res.question)
          this.$set(this, 'currentQuestion', currentQuestion)
          this.currentQuestionIndex = this.currentQuestionIndex + 1
          this.$set(this.questions, this.currentQuestionIndex, currentQuestion)

          this.timers.currentTimer.reset()
          this.timers.currentTimer.start()
          // this.timers.totalTimer.start()
        } else {
          this.$router.push({
            name: 'CatReport',
            query: {
              id: this.testId,
            },
          })
        }
      } catch (error) {
        this.$message.warning({
          message: '网络异常，请重新尝试',
        })
        this.loading = false
      }
    },
    async catTestTimeout(answer) {
      await this.submitCatAnswer(answer)
      let params = {
        testId: this.testId,
      }
      await this.$service.catTestTimeout(params)
      this.$message.info({
        message: '测试时间到,已为您自动提交试题答案',
      })
      this.$router.push({
        name: 'CatReport',
        query: {
          id: this.testId,
        },
      })
    },
  },
  mounted() {
    this.init()
  },
}
</script>
<style lang="scss" scoped>
.start-testing {
  .testing-header {
    background-color: #fff;
    .flex-right {
      min-width: 185px;
      padding: 14px 44px;
      border-left: 1px solid #fafafa;
    }
    .progress-bar {
      height: 8px;
      background: #eeeeee;
      .slider {
        height: 8px;
        background: #0148ff;
        border-radius: 0px 4px 4px 0px;
      }
    }
  }
  .el-main {
    padding: 12px;
  }
}
</style>