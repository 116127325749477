<!--
 * @Author: wyq
 * @Date: 2021-07-30 15:19:02
 * @LastEditTime: 2021-09-29 14:52:51
 * @LastEditors: wyq
 * @Description: 
 * @FilePath: \cat_student\src\components\dialogs\finishCatMsgBox.vue
-->
<template>
  <msg-box-template
    :visible.sync="dialogVisible"
    title="结束训练"
    :imgUrl="require('@/assets/images/dialog/end.png')"
  >
    是否确定退出测试？
    <p class="color-error">本次测试将作废</p>
    <template slot="btnGroup">
      <v-btn color="error" depressed x-large outlined @click="submit" :loading="submitLoadding">退出测试</v-btn>
      <v-btn color="primary" depressed x-large @click="close">继续努力</v-btn>
    </template>
  </msg-box-template>
</template>
<script>
import msgBoxTemplate from './msgBoxTemplate'
import mixin from '@/utils/dialogMixin'
export default {
  components: {
    msgBoxTemplate,
  },
  mixins: [mixin],
}
</script>
<style lang="scss" scoped>
.color-error {
  color: #ff3838;
}
</style>